import React from "react";
import cover from "../uploads/cover.jpg";
import "../css/Cover.css";

export const Cover = () => {
  const coverImage = localStorage.getItem("coverImage");
  let coverTemp;
  if (coverImage === null) {
    localStorage.setItem("coverImage", cover);
    coverTemp = cover;
  } else {
    coverTemp = coverImage;
  }

  return (
    <div className="coverContainer">
      <img src={coverTemp} alt="cover" className="coverImg" />

      <div className="coverTitle">
        <div className="mainContent">
          We create <span className="mainContentHighlighted">it solutions</span>
        </div>
        <div className="mainContent"> for a connected world</div>

        <div className="subContent">We focus on your business goals</div>
        <div className="subContent">
          by providing only the most effective and efficient IT solutions
        </div>
      </div>
    </div>
  );
};
