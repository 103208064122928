import React from "react";
import "../css/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faHandshakeSimple,
  faRocket,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";

export const About = () => {
  return (
    <div className="aboutContainer">
      <header className="aboutHeader">about us</header>

      <div className="aboutContent">
        Polystone is a growing provider of IT services and solutions to small
        and medium-sized businesses. We offer a wide range of services, such as
        cloud computing, managed services, software development, cybersecurity,
        and consulting.
      </div>

      <div className="aboutContent">
        We believe that every company has its own story. And each of those
        stories is unique. At Polystone, we want to listen to yours. That's why
        we focus on understanding your goals and challenges. Then, we create
        customized solutions that meet your specific needs.
      </div>

      <div className="aboutSloganContainer">
        <div className="aboutSlogan">
          Passionate, Insightful, Solution Oriented, Powerful
        </div>
        <div className="aboutSlogan">
          Ploystone Solutions will help you get{" "}
          <span className="aboutSloganHighlight">results</span>.
        </div>
      </div>

      <div className="aboutBenefitsContainer">
        <section className="aboutBenefitsSection">
          <FontAwesomeIcon
            icon={faHandshakeSimple}
            className="aboutBenefitsImg"
          />

          <div className="aboutBenefitsContentContainer">
            <div className="aboutBenefitsHeader">Client Experiences</div>
            <div className="aboutBenefitsContent">
              Transform client experiences, leverage the power of modern IT
              infrastructure, data analysis, and application development.
            </div>
          </div>
        </section>

        <section className="aboutBenefitsSection">
          <FontAwesomeIcon
            icon={faHandHoldingDollar}
            className="aboutBenefitsImg"
          />

          <div className="aboutBenefitsContentContainer">
            <div className="aboutBenefitsHeader">Manage Costs</div>
            <div className="aboutBenefitsContent">
              Optimize your IT investments by implementing cost-effective
              technology solutions, consumption-based services, flexible pricing
              models, etc.
            </div>
          </div>
        </section>

        <section className="aboutBenefitsSection">
          <FontAwesomeIcon icon={faShieldHalved} className="aboutBenefitsImg" />

          <div className="aboutBenefitsContentContainer">
            <div className="aboutBenefitsHeader">Mitigate Risks</div>
            <div className="aboutBenefitsContent">
              Protect your data, systems, users and customers against a rapidly
              evolving landscape of threats whether you're updating systems.
            </div>
          </div>
        </section>

        <section className="aboutBenefitsSection">
          <FontAwesomeIcon icon={faRocket} className="aboutBenefitsImg" />

          <div className="aboutBenefitsContentContainer">
            <div className="aboutBenefitsHeader">Operational Efficiencies</div>
            <div className="aboutBenefitsContent">
              Improve performance and operations by streamlining processes with
              technology solutions and services.
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
