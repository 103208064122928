import React, { useEffect, useState } from "react";
import "../css/Header.css";
import icon from "../uploads/icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {
  const iconImage = localStorage.getItem("iconImage");
  let iconTemp;
  if (iconImage === null) {
    localStorage.setItem("iconImage", icon);
    iconTemp = icon;
  } else {
    iconTemp = iconImage;
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showBar, setShowBar] = useState(false);

  const handleRefresh = () => {
    window.location.href = "/";
  };

  const handleShowBar = () => {
    setShowBar(!showBar);
  };

  const handleScrollToBottom = () => {
    if (showBar === true) {
      setShowBar(false);
    }

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleScrollToAbout = () => {
    if (showBar === true) {
      setShowBar(false);
    }

    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const handleScrollToServices = () => {
    if (showBar === true) {
      setShowBar(false);
    }

    window.scrollTo({
      top: window.innerHeight * 2,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="headerContainer">
        <div className="headerLogoContainer" onClick={handleRefresh}>
          <img src={iconTemp} alt="icon" className="headerLogo" />
        </div>

        {windowWidth >= 1025 ? (
          <>
            <section className="headerSectionContainer">
              <div onClick={handleRefresh}>
                <span>home</span>
              </div>
              <div onClick={handleScrollToAbout}>
                <span>about</span>
              </div>
              <div onClick={handleScrollToServices}>
                <span>services</span>
              </div>
              <div onClick={handleScrollToBottom}>
                <span>contact us</span>
              </div>
            </section>
            <div className="headerLogoContainer"></div>
          </>
        ) : (
          <div className="headerSection1024">
            <div onClick={handleShowBar}>
              <FontAwesomeIcon icon={faBars} className="headerSectionIcon" />
            </div>
          </div>
        )}
      </div>

      {showBar && (
        <div className="headerPage">
          <div className="headerContainer">
            <div className="headerLogoContainer">
              <img src={icon} alt="icon" className="headerLogo" />
            </div>
            <div className="headerSection1024">
              <div onClick={handleShowBar}>
                <FontAwesomeIcon icon={faXmark} className="headerSectionIcon" />
              </div>
            </div>
          </div>

          <ul className="headerPageIndex">
            <li onClick={handleRefresh}>home</li>
            <li onClick={handleScrollToAbout}>about</li>
            <li onClick={handleScrollToServices}>services</li>
            <li onClick={handleScrollToBottom}>contact us</li>
          </ul>
        </div>
      )}
    </>
  );
};
