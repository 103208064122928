import React from "react";
import "./App.css";
import { Header } from "./_components/Header";
import { Cover } from "./_components/Cover";
import { About } from "./_components/About";
import { Footer } from "./_components/Footer";
import { Services } from "./_components/Services";

function App() {
  return (
    <div>
      <Header />
      <Cover />
      <About />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
