import React from "react";
import "../css/Services.css";
export const Services = () => {
  return (
    <div className="servicesContainer">
      <header className="servicesHeader">Services</header>

      <div className="servicesSectionContainer">
        <section className="servicesSection">
          <header className="servicesSectionHeader">Consulting Services</header>
          <div className="servicesSectionContent">
            Whether you are constructing an establishment for innovation or
            improving existing processes, our consulting services give
            data-driven perceptivity to transform how technology drives your
            business.
          </div>
        </section>

        <section className="servicesSection">
          <header className="servicesSectionHeader">Cloud Services</header>
          <div className="servicesSectionContent">
            Our teams have extensive experience working with AWS, AZURE, and
            Google Cloud environments. We tailor our solutions to our client's
            specific needs, ensuring complete reliability, flexibility,
            security, customization, and cost-effectiveness.
          </div>
        </section>

        <section className="servicesSection">
          <header className="servicesSectionHeader">
            Application Development
          </header>
          <div className="servicesSectionContent">
            We provide custom application development tailored to the client's
            business requirements on various platforms including Web, Mobile,
            and PC. We pay special attention to their flexibility, security,
            speed and integration potential in the process.
          </div>
        </section>

        <section className="servicesSection">
          <header className="servicesSectionHeader">CMS Development</header>
          <div className="servicesSectionContent">
            We provide CMS development services that enable you to update your
            website content in real time from anywhere in the world. Our CMS web
            developers specialize in custom-made CMS solutions or build them on
            the WordPress CMS platform.
          </div>
        </section>
      </div>
    </div>
  );
};
