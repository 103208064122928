import React from "react";
import "../css/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  const handleRefresh = () => {
    window.location.href = "/";
  };

  const handleScrollToAbout = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const handleScrollToServices = () => {
    window.scrollTo({
      top: window.innerHeight * 2,
      behavior: "smooth",
    });
  };

  return (
    <div className="footerContainer">
      <div className="footerInfo">
        <section className="socialMediaContainer">
          <a
            href="https://www.instagram.com/polystone_solutions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61556116743471&mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://wa.me/+85292251656"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </section>

        <section className="footerSectionContainer">
          <div onClick={handleRefresh}>
            <b>Polystone</b>
          </div>
          <div onClick={handleScrollToAbout}>About us</div>
          <div onClick={handleScrollToServices}>Services</div>
          <div>Join us</div>
        </section>

        <section className="footerSectionContainer">
          <div>
            <FontAwesomeIcon icon={faPhone} /> <span>+852 9225 1656</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
            <span>info@polystonehk.com</span>
          </div>
        </section>

        <section className="seoTagContainer">
          <div>
            網貼設計 電商 網店 圖表分析 搜尋引擎優化 網上付款物流電商 POS系統
            網頁設計 網頁設計公司 增加生意網頁設計公司香港 客服功能 網絡安全穩定
            寄存空間 雲應式網頁 網頁寄存 個性化增加收人 網上生意 網上經營 SEO
            Google Analytics Google分析 流量搜尋 引擎優化
          </div>
        </section>
      </div>

      <div className="copyrightContainer">
        Copyright © Polystone Solutions. All right reserved.
      </div>
    </div>
  );
};
